import $ from 'jquery';
document.jQuery = global.jQuery = $;
document.$ = global.$ = $;
document.jquery = global.jquery = $;

require('vendor/jquery-ui-min');
require('vendor/jquery_ujs');
require('bootstrap');
require('vendor/jquery-jvectormap-1.2.2.min')
require('vendor/jquery-jvectormap-us-aea-en')
require('vendor/bootstrap-datepicker')
require('vendor/bootstrap-datepicker')
require('vendor/owl.carousel.min')
require('vendor/jquery.mousewheel')
// require('vendor/jquery.mCustomScrollbar')
require('vendor/plugins')

import {pageLoadingFrame, panel_refresh} from 'vendor/actions'
window.panel_refresh = panel_refresh;
window.pageLoadingFrame = pageLoadingFrame
window.numeral = require('vendor/numeral.min')
window.moment = require('moment');
window.local_time = require('vendor/local_time');
window.Cookies = require('vendor/js.cookie');

require('vendor/daterangepicker');
require('vendor/multiselect.min');
require('vendor/bootstrap-switch.min');
require('vendor/farbtastic');

window.Dropzone = require('vendor/dropzone');

require('vendor/tiny_color.min');
require("@nathanvda/cocoon")
require("vendor/soundmanager2/soundmanager2")
require("vendor/soundmanager2/bar-ui")

window._ = require("lodash")

require("vendor/bootstrap-notify.min")
require("vendor/jquery.maskedinput.min")
require("vendor/validationengine/languages/jquery.validationEngine-en");
require("vendor/validationengine/jquery.validationEngine");
require("vendor/jquery-validation/jquery.validate");

window.Bloodhound = require("vendor/typeahead.bundle.min")
import {handleError} from "utils/util_methods"
window.handleError = handleError
  